<template>
  <div class="app-container">
    <el-tabs v-model="tab" @tab-click="handleTabChange">
      <el-tab-pane name="unified" label="统一设置" />
      <el-tab-pane name="target" label="指定经销商" />
    </el-tabs>
    <div class="h sb">
      <el-card header="经销商列表" shadow="never" style="margin-right: 20px; width: 30%;" v-show="tab === 'target'">
        <div class="head-container">
          <el-input v-model="query.name" clearable placeholder="输入企业名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
          <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
        </div>
        <el-table v-loading="loading" :data="data" size="small" highlight-current-row @current-change="loadSpecialRule" style="width: 100%;">
          <el-table-column prop="erpId" label="ERP编号" width="120" />
          <el-table-column prop="name" label="企业名称" show-overflow-tooltip />
        </el-table>

        <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
      </el-card>
      <el-card shadow="never" class="flex" v-loading="ruleLoading">
        <div v-if="tab === 'unified'">设置在使用返利时需要达到的条件和可以使用的额度，当指定经销商没有单独设置规则时适用“统一设置”中的规则。</div>
        <div v-else>为指定的经销商设置其单独的使用返利规则。</div>
        <div class="fc-e">注意：以下各项设置为0时，表示不进行限制。</div>
        <el-form ref="form" :model="form" :rules="rules" label-suffix=":" :disabled="!editable" label-position="right" hide-required-asterisk label-width="74px" style="margin-top: 20px;">
          <el-form-item label="是否启用" v-if="tab === 'target'">
            <el-switch v-model="form.enable" />
          </el-form-item>
          <template v-if="form.enable">
            <el-form-item label="订单满额">
              <div class="h c">
                <price-input v-model.trim="form.minMoney" :min="0" style="width:150px;" />
                <span class="padding-0-10 fc-g">当订单的金额达到此金额才可使用返利</span>
              </div>
            </el-form-item>
            <el-form-item label="使用比例">
              <div class="h c">
                <percent-input :base="10000" v-model.trim="form.limitRaito" style="width:150px;" />
                <span class="padding-0-10 fc-g">可使用的返利金额不超过订单金额的百分比例</span>
              </div>
            </el-form-item>
            <el-form-item label="使用限额">
              <div class="h c">
                <price-input v-model.trim="form.limitMoney" :min="0" style="width: 150px;" />
                <span class="padding-0-10 fc-g">可使用的返利金额不超过此金额</span>
              </div>
            </el-form-item>
            <el-form-item prop="dateRange" label="生效日期" v-if="tab === 'target'">
              <el-date-picker v-model="form.dateRange" type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" />
            </el-form-item>
          </template>
          <el-form-item>
            <el-button :loading="saving" type="primary" @click="doSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import initData from "@/mixins/initData";

import request from "@/utils/request";
export default {
  mixins: [initData],
  data() {
    return {
      current: null,
      tab: "unified",
      ruleLoading: false,
      saving: false,
      form: {
        limitMoney: 0,
        limitRaito: 0,
        minMoney: 0
      },
      query:{
        name:null,
      },
      rules: {
        dateRange: [
          { required: true, message: "请选择生效日期", trigger: "change" },
          {
            type: "array",
            min: 2,
            max: 2,
            message: "请选择生效日期",
            trigger: "change"
          }
        ]
      }
    };
  },
  created() {
    this.init();
    this.load();
  },
  methods: {
    beforeInit() {
      this.url = "api/distributor";
      const sort = "id,desc";
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: sort },
        this.query
      );
      return true;
    },
    handleTabChange(tab) {
      if (tab.name === "unified") {
        this.load();
      } else {
        this.loadSpecialRule(this.current);
      }
    },
    load() {
      this.ruleLoading = true;
      this.editable = false;
      request({
        url: "/api/rebateUseSet",
        method: "get"
      })
        .then(res => {
          this.form = Object.assign(
            res || {
              limitMoney: 0,
              limitRaito: 0,
              minMoney: 0
            },
            { enable: true }
          );
        })
        .finally(_ => {
          this.editable = true;
          this.ruleLoading = false;
        });
    },
    loadSpecialRule(row) {
      this.current = row;
      if (row) {
        this.ruleLoading = true;
        this.editable = false;
        request({
          url: "/api/rebateUseSet/buyer/" + row.enterpriseId,
          method: "get"
        })
          .then(res => {
            let o;
            if (res) {
              o = Object.assign(res, {
                enable: true,
                dateRange: [res.begTime, res.endTime]
              });
            } else {
              o = {
                buyerId: row.enterpriseId,
                limitMoney: 0,
                limitRaito: 0,
                minMoney: 0,
                dateRange: [],
                enable: false
              };
            }
            this.form = o;
          })
          .finally(_ => {
            this.editable = true;
            this.ruleLoading = false;
          });
      } else {
        this.form = {
          limitMoney: 0,
          limitRaito: 0,
          minMoney: 0
        };
        this.editable = false;
      }
    },
    doSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          if (this.tab === "unified") {
            let data = {
              limitMoney: this.form.limitMoney,
              limitRaito: this.form.limitRaito,
              minMoney: this.form.minMoney
            };
            this.saving = true;
            request({
              url: "api/rebateUseSet",
              method: "put",
              data: data
            })
              .then(res => {
                this.$notify({
                  title: "统一使用返利规则保存成功",
                  type: "success",
                  duration: 2500
                });
              })
              .finally(_ => {
                this.saving = false;
              });
          } else if (this.form.enable) {
            let d = JSON.parse(JSON.stringify(this.form));
            d.begTime = d.dateRange[0];
            d.endTime = d.dateRange[1];
            delete d.dateRange;
            delete d.enable;
            this.saving = true;
            request({
              url: "api/rebateUseSet/buyer",
              method: "put",
              data: d
            })
              .then(res => {
                this.$notify({
                  title: "指定经销商的使用返利规则保存成功",
                  type: "success",
                  duration: 2500
                });
              })
              .finally(_ => {
                this.saving = false;
              });
          } else if (this.form.id) {
            this.saving = true;
            request({
              url: "api/rebateUseSet/buyer/" + this.form.id,
              method: "delete"
            })
              .then(res => {
                this.$notify({
                  title: "指定经销商的使用返利规则保存成功",
                  type: "success",
                  duration: 2500
                });
              })
              .finally(_ => {
                this.saving = false;
              });
          }
        });
    }
  }
};
</script>